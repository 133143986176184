import {
  AbilityBuilder,
  AbilityClass,
  ConditionsMatcher,
  mongoQueryMatcher,
  PureAbility,
} from '@casl/ability';

import type { LcmUserSession } from './types/next-auth';

enum Action {
  Create = 'create',
  Edit = 'edit',
  View = 'view',
  List = 'list',
  Manage = 'manage',
  Vote = 'vote',
  Facilitate = 'facilitate',
  Proxy = 'proxy',
  Unlock = 'unlock',
  Skip = 'skip',
  Delete = 'delete',
}

export type Entities =
  | 'Nominations'
  | 'Admin'
  | 'Home'
  | 'DownloadDocuments'
  | 'Proxy'
  | 'User'
  | 'RoleSetting'
  | 'ManagerApproval'
  | 'Evaluations'
  | 'Overview'
  | 'FeatureFlags'
  | 'NominationsControls'
  | 'Reports'
  | 'Cheershub'
  | 'Footer'
  | 'Stellar'
  | 'Idp'
  | 'TeamReports'
  | 'TeamIdp'
  | 'TeamAssessments';

export type AbilityTuple = [`${Action}`, Entities | Record<string, unknown>];

export type AppAbility = PureAbility<AbilityTuple>;

export function createUserAbility(
  user?: LcmUserSession['user'],
  rules?: AppAbility['_indexedRules']
) {
  const { can, build } = new AbilityBuilder(
    PureAbility as AbilityClass<AppAbility>
  );

  const notProxiedUserRoles = user?.roles;
  const canBeProxiedUserRoles = user?.proxiedAs?.roles ?? user?.roles;

  const isManager = user?.proxiedAs?.isManager ?? user?.isManager;

  const isLiveManager = user?.proxiedAs?.isLiveManager ?? user?.isLiveManager;

  const isParticipant = user?.proxiedAs?.isParticipant ?? user?.isParticipant;

  const isInEvaluationPeriod =
    user?.proxiedAs?.processStatus?.isInEvaluationPeriod ??
    user?.processStatus?.isInEvaluationPeriod;

  const hasCheersHubPermission =
    user?.proxiedAs?.cheersHubPermission ?? user?.cheersHubPermission;

  const hasDownloadDocumentsPermission =
    user?.proxiedAs?.downloadDocumentsPermission ??
    user?.downloadDocumentsPermission;

  const hasTheNominationsPeriodStarted =
    user?.proxiedAs?.processStatus?.hasTheNominationsPeriodStarted ??
    user?.processStatus?.hasTheNominationsPeriodStarted;

  const isInNominationsPeriod =
    user?.proxiedAs?.processStatus?.isInNominationsPeriod ??
    user?.processStatus?.isInNominationsPeriod;

  const isInHideNominationScreenPeriod =
    user?.proxiedAs?.processStatus?.isInHideNominationScreenPeriod ??
    user?.processStatus?.isInHideNominationScreenPeriod;

  const isInNominationsReadOnlyPeriod =
    user?.proxiedAs?.processStatus?.isInNominationsReadOnlyPeriod ??
    user?.processStatus?.isInNominationsReadOnlyPeriod;

  const canSeeEvaluations =
    user?.proxiedAs?.canSeeEvaluations ?? user?.canSeeEvaluations;

  const canSeeEvaluationsOverview =
    user?.proxiedAs?.canSeeEvaluationsOverview ??
    user?.canSeeEvaluationsOverview;

  const isAdminOrPbp =
    canBeProxiedUserRoles?.IS_LCM_GLOBAL_ADMIN ||
    canBeProxiedUserRoles?.IS_LCM_ZONE_ADMIN ||
    canBeProxiedUserRoles?.IS_PBP;

  const isAdminGlobal = canBeProxiedUserRoles?.IS_LCM_GLOBAL_ADMIN;

  const hasMyTeamIdpPermission =
    user?.proxiedAs?.canSeeMyTeamIdp ?? user?.canSeeMyTeamIdp;
  const hasMyTeamResultsPermission =
    user?.proxiedAs?.canSeeMyTeamResults ?? user?.canSeeMyTeamResults;

  const hasIdpPermission =
    user?.proxiedAs?.idpPermission ?? user?.idpPermission;

  const canSeeMyTeamCompletion =
    user?.proxiedAs?.canSeeMyTeamCompletion ?? user?.canSeeMyTeamCompletion;

  can(Action.View, 'Home');

  if (hasCheersHubPermission) {
    can(Action.View, 'Cheershub');
  }

  if (canSeeEvaluations) {
    can(Action.View, 'Evaluations');
  }

  if (isInEvaluationPeriod) {
    can(Action.Edit, 'Evaluations');
  }

  if (canSeeEvaluations && canSeeEvaluationsOverview) {
    can(Action.View, 'Overview');
  }

  if (hasTheNominationsPeriodStarted && !isInNominationsReadOnlyPeriod) {
    can(Action.View, 'NominationsControls');
  }

  if (
    isParticipant &&
    !isInHideNominationScreenPeriod &&
    (hasTheNominationsPeriodStarted || isInNominationsReadOnlyPeriod)
  ) {
    can(Action.View, 'Nominations');
  }
  if (isParticipant || isAdminGlobal) {
    can(Action.View, 'Reports');
  }

  if (notProxiedUserRoles?.IS_LCM_GLOBAL_ADMIN) {
    can(Action.Proxy, 'User');
  }

  if (
    canBeProxiedUserRoles?.IS_LCM_GLOBAL_ADMIN ||
    canBeProxiedUserRoles?.IS_LCM_ZONE_ADMIN
  ) {
    can(Action.Create, 'Admin');
  }

  if (canBeProxiedUserRoles?.IS_LCM_GLOBAL_ADMIN) {
    can(Action.Manage, 'RoleSetting');
  }

  if (isAdminOrPbp) {
    can(Action.View, 'Admin');
    can(Action.View, 'Footer');
  }

  if (hasDownloadDocumentsPermission) {
    can(Action.View, 'DownloadDocuments');
  }

  if (isAdminOrPbp || isLiveManager || isAdminGlobal) {
    can(Action.Manage, 'Reports');
  }

  if (hasIdpPermission) {
    can(Action.View, 'Idp');
  }

  if (
    [
      canBeProxiedUserRoles?.IS_LCM_GLOBAL_ADMIN,
      canBeProxiedUserRoles?.IS_PBP,
    ].includes(true)
  ) {
    can(Action.View, 'Stellar');
  }

  if (isManager && isInNominationsPeriod) {
    can(Action.View, 'ManagerApproval');
  }

  if (hasMyTeamResultsPermission && isLiveManager) {
    can(Action.View, 'TeamReports');
  }

  if (hasMyTeamIdpPermission) {
    can(Action.View, 'TeamIdp');
  }

  if (isLiveManager && canSeeMyTeamCompletion) {
    can(Action.View, 'TeamAssessments');
  }

  const ability = build({
    conditionsMatcher: mongoQueryMatcher as ConditionsMatcher<unknown>,
  });

  if (rules) {
    ability.update(rules);
  }

  return ability;
}
