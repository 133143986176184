import * as React from 'react';
import { Avatar as DSAvatar } from '@ghq-abi/design-system';

import { useGetProfilePicture } from '../../../entities/Employee/hooks';

type AvatarProps = React.ComponentProps<typeof DSAvatar> & {
  globalId?: string;
};

export function Avatar({ globalId, ...rest }: AvatarProps) {
  const { imageSrc } = useGetProfilePicture(globalId ?? '');

  return (
    <DSAvatar src={globalId && imageSrc ? imageSrc : undefined} {...rest} />
  );
}
