import { useQuery } from '@tanstack/react-query';
import { GetAllZonesModelView, ZoneModelView } from 'abi-lcm-common-types';

import { useUser } from '~/shared/auth/useUser';
import { lcmApi } from '~/shared/services/api';

async function getZones() {
  const response = await lcmApi.get<GetAllZonesModelView>('zone');

  return {
    ...response,
    data: response.data.zones,
  };
}

type UseGetZonesOptions = {
  enabled?: boolean;
  filterByManagedZones?: boolean;
};

function mapZonesToNames(data: ZoneModelView[]) {
  const zonNames = data.map((zone: ZoneModelView) => zone.name);

  const MAP_OF_ZONES = new Map<string, string>();

  data.forEach((item, index) => {
    MAP_OF_ZONES.set(item.acronym, zonNames[index]);
  });

  return Array.from(MAP_OF_ZONES.values());
}

export function useGetZones({
  enabled = true,
  filterByManagedZones,
}: UseGetZonesOptions = {}) {
  const user = useUser();

  const zonesQuery = useQuery({
    queryKey: ['useGetZones'],
    queryFn: getZones,
    select: ({ data }) => {
      const zones = mapZonesToNames(data);

      return {
        zones: [...zones]
          .sort((a, b) => a.localeCompare(b))
          .filter(Boolean)
          .filter(zone => {
            if (
              user.roles?.IS_LCM_ZONE_ADMIN &&
              !user.roles.IS_LCM_GLOBAL_ADMIN &&
              filterByManagedZones
            ) {
              return user.zones?.includes(zone);
            }

            return true;
          }),
      };
    },
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled,
  });

  return {
    zonesData: zonesQuery.data?.zones,
    isLoading: zonesQuery.isLoading && zonesQuery.fetchStatus !== 'idle',
  };
}
