import * as React from 'react';
import { Avatar } from '@ghq-abi/design-system';

import { useGetProfilePicture } from '~/entities/Employee/hooks';

type AvatarProps = React.ComponentProps<typeof Avatar> & {
  globalId?: string;
};

export function ProfileAvatar({ globalId, ...rest }: AvatarProps) {
  const { imageSrc } = useGetProfilePicture(globalId ?? '');

  return <Avatar src={globalId && imageSrc ? imageSrc : undefined} {...rest} />;
}
