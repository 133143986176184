import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import { signOut } from 'next-auth/react';
import { useTranslate } from '@tolgee/react';
import nprogress from 'nprogress';

import { useAbility } from '~/app/contexts/AbilityContext';
import { useSessionInfo } from '~/app/contexts/SessionInfoContext';
import { useStopProxy } from '~/entities/Proxy/api';
import { AuthenticatedUser } from '~/shared/auth/types/next-auth';
import { Icon } from '~/shared/components';
import { SignOut } from '~/shared/components/icons/SignOut';
import { nextApi } from '~/shared/services/server-api';

import { useGetProfilePicture } from '../../../../entities/Employee';

type UseUserDropdownProps = {
  user: AuthenticatedUser;
};

export const useUserDropdown = ({ user }: UseUserDropdownProps) => {
  const { t } = useTranslate(['default']);
  const { sessionInfo: session } = useSessionInfo();
  const stopProxyMutation = useStopProxy();
  const router = useRouter();
  const ability = useAbility();

  const isProxying = !!session?.user?.proxiedAs;

  async function handleSignOut() {
    try {
      await nextApi.post('/destroy-cookies');
    } finally {
      signOut({ redirect: false });
    }
  }

  const goToProxy = useCallback(async () => {
    router.push('/proxy');
  }, [router]);

  const handleStopProxy = useCallback(async () => {
    if (!session) {
      return;
    }

    nprogress.start();

    stopProxyMutation.mutate(undefined, {
      onError: error => {
        nprogress.done();
        console.error('Error stopping proxy:', error);
      },
      onSuccess: () => {
        nprogress.done();
        window.location.reload();
      },
    });
  }, [session, stopProxyMutation]);

  const items = useMemo(() => {
    const dropdownItems = [
      {
        label: t('common.sign_out'),
        onSelect: handleSignOut,
        icon: <SignOut />,
      },
    ];

    if (ability.can('proxy', 'User')) {
      dropdownItems.unshift({
        label: t(isProxying ? 'common.stop_proxy' : 'common.proxy_as_user'),
        onSelect: isProxying ? handleStopProxy : goToProxy,
        icon: isProxying ? (
          <Icon.StopProxy size={18} />
        ) : (
          <Icon.Proxy size={18} />
        ),
      });
    }

    return dropdownItems;
  }, [ability, goToProxy, handleStopProxy, isProxying, t]);

  const { data } = useGetProfilePicture(user.globalId ?? '');
  const imageSrc = data ? URL.createObjectURL(data) : undefined;

  const profileInfo = {
    name: user.name ?? '',
    urlImage: imageSrc,
    zone: user.zone ?? '',
  };

  return {
    items,
    profileInfo,
  };
};
