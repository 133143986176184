import { useQuery } from '@tanstack/react-query';

import { peoplePlatformApi } from '~/shared/services/api';

async function getProfilePicture(globalId?: string) {
  if (!globalId) return null;
  const response = await peoplePlatformApi.get<Blob>(
    `/photo/thumb/${globalId}`,
    {
      responseType: 'blob',
    }
  );

  return response;
}

export function useGetProfilePicture(globalId: string) {
  const query = useQuery({
    queryKey: ['getProfilePicture', globalId],
    queryFn: () => getProfilePicture(globalId),
    select: data => data?.data,
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const imageSrc = query?.data ? URL.createObjectURL(query.data) : null;

  return Object.assign(query, {
    imageSrc,
  });
}
