import { LanguageDropdown, Skeleton } from '@ghq-abi/design-system';

import { useLanguageDropdown } from './hooks';
import { LanguageDropdownItem } from './types';

type LanguageDropdownProps = {
  currentLanguage?: string;
};

export const LanguageDropdownHeader = ({
  currentLanguage,
}: LanguageDropdownProps) => {
  const {
    availableLanguages,
    selectedLanguageLabel,
    isLoadingLanguages,
    handleLanguageChange,
  } = useLanguageDropdown(currentLanguage);

  if (isLoadingLanguages) {
    return <Skeleton variant="rectangle" height={46} width={124} />;
  }

  const languageOptions = availableLanguages.map(language => ({
    label: language.label,
    onSelect: () => handleLanguageChange(language as LanguageDropdownItem),
  }));

  return (
    <LanguageDropdown value={selectedLanguageLabel} items={languageOptions} />
  );
};
